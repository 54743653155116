<template>
    <div class="d-flex flex-column pb-3">
        <img v-if="model" :src="`/b-icons/${model}.svg`" class="icon-xl mb-3"/>
        <img v-else src="/dealbuzzer_logo_icon_white.svg" class="icon-xl mb-3 bg-black"/>
        <div>
            <div class="d-flex gap-1">
                <button role="button" class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        data-bs-auto-close="outside" aria-expanded="false">
                    {{ $t('eventType.iconPicker.pickIcon') }}
                </button>
                <div class="dropdown-menu db-shadow p-2">
                    <input type="text" class="form-control" :placeholder="$t('eventType.iconPicker.search')" v-model="query">
                    <div v-if="iconBatch.length === 0" class="mt-2 text-center">
                        <p>{{ $t('eventType.iconPicker.noIconsFound') }}</p>
                    </div>
                    <div v-else class="picker-body" ref="pickerArea">
                        <div v-for="icon of iconBatch">
                            <button role="button" class="btn my-1" @click.prevent="model = icon">
                                <img :src="`/b-icons/${icon}.svg`" class="icon-sm"/>
                            </button>
                        </div>
                    </div>
                </div>
                <button v-if="model" class="btn btn-secondary" @click.prevent="resetIcon()">
                    {{ $t('eventType.iconPicker.deleteIcon') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useScroll} from "@vueuse/core";
import { iconData as icons } from "~/components/pages/eventTypes/B-IconsData";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        required: false
    }
})

const model = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

const pickerArea = ref(null);

const {arrivedState} = useScroll(pickerArea)

watch(arrivedState, (state) => {
    if (state.bottom === true) currentBatch.value++;
})

const currentBatch = ref(0);

const query = ref('');

const iconBatch = computed(() => {
    const batchSize = 26;
    if (query.value !== "") {
        return icons
            .filter(icon => icon.includes(query.value))
            .slice(0, batchSize);
    }
    return icons.slice(0, (currentBatch.value + 1) * batchSize)
})

const resetIcon = () => {
    model.value = '';
}
</script>

<style scoped>
.picker-body {
    display: grid;
    height: 250px;
    overflow-y: scroll;
    grid-template-columns: repeat(3, 1fr)
}

.icon-sm {
    height: 30px;
    width: 30px;
}

.icon-xl {
    height: 100px;
    width: 100px;
}
</style>
